@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
  --primary-color: #0DFF92;
  --dark-color: #222222;
  --light-color: #f0f0f0;
}


body, html {
  height: 100%;

}


*>* {
  font-family: 'Poppins', sans-serif;
}

.container {
  display: block;
  position: relative;
  margin: 40px auto;
  height: auto;
  width: 800px;
  padding: 20px;
}

.container .title {
  font-size: 3em;
  text-align: center;
  border-bottom: 2px solid #00ff80;
  padding: .3em .2em;
  border-radius: 4px;
}

.text-light {
  color: var(--light-color)
}

.container ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.container .questions {
  padding: 3em;
}



.container .grid {
  margin-top: 3em;
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.container .btn {
  padding: .2em 1.7em;
  border: none;
  border-radius: .1em;
  font-size: 1.2em;
}

.container .btn:hover {
  cursor: pointer;
  background-color: #f0f0f0;
  color: #202020;
}

.next {
  background-color: var(--primary-color);
  justify-self: flex-end;
}

.prev {
  background-color: #faff5a;
  justify-self: flex-start;
}

ul li {
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #333;
}

ul li input[type=radio] {
  position: absolute;
  visibility: hidden;
}

ul li label {
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1.35em;
  padding: 25px 25px 25px 80px;
  margin: 10px auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

ul li:hover label {
  color: #FFFFFF;
}

ul li .check {
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 30px;
  left: 20px;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
}

ul li:hover .checked {
  border: 5px solid #FFFFFF;
}

ul li .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 50%;
  height: 12px;
  width: 12px;
  bottom: 2px;
  right: 0;
  left: 0;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}


input[type=radio]:checked~.check {
  border: 5px solid var(--primary-color)
}

input[type=radio]:checked~.check::before {
  background: var(--primary-color)
}

input[type=radio]:checked~.text-primary {
  color: var(--primary-color)
}

/* To get selected option we are using this checked class */
.checked {
  border: 5px solid var(--primary-color) !important;
}

.checked::before {
  background: var(--primary-color)
}



html {
  font-size: 1rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.6rem;
  }
}


.title {
  font-family: 'Chivo Mono', monospace;
  font-size: larger;
  color: #faff5a;
}


/* Styling for table */
/* Styling for table */
table {
  width: 100%;
  max-width: 800px;
  /* Set a max-width for responsive design */
  margin: 0 auto;
  /* Center the table horizontally */
  border-collapse: collapse;
}

.table-header {
  background-color: #f2f2f2;
  font-weight: bold;
}

.table-row {
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
}

.table-row:hover {
  background-color: #f9f9f9;
}

.table-body td {
  padding: 10px;
}

/* Button animations */
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #007bff;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  border: none;
  cursor: pointer;
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.button:hover {
  background-color: #0056b3;
}

.button:focus {
  outline: none;
}


.bgimg {
  /* background-image: url('../assets/wallpaper.gif');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw; */
}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

/* home screen */

.card-shutter-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 90vw;
  border: 5px solid #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #00ff00,
    0 0 70px #00ff00, 0 0 80px #00ff00, 0 0 100px #00ff00, 0 0 150px #00ff00;
  animation: slide-in 1s forwards;
  background: url('../assets/wave.svg');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .card-shutter-content {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}



/* SUN BUTTON */
.containerx {
  width: 350px;
  height: 350px;
  margin: 100px auto;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cloud {
  width: 250px;
}

.front {
  padding-top: 45px;
  margin-left: 25px;
  display: inline;
  position: absolute;
  z-index: 11;
  animation: clouds 8s infinite;
  animation-timing-function: ease-in-out;
}

.back {
  margin-top: -30px;
  margin-left: 150px;
  z-index: 12;
  animation: clouds 12s infinite;
  animation-timing-function: ease-in-out;
}

.right-front {
  width: 55px;
  height: 35px;
  border-radius: 50% 50% 50% 0%;
  background-color: #cfd9e5;
  display: inline-block;
  margin-left: -25px;
  z-index: 5;
}

.left-front {
  width: 75px;
  height: 55px;
  border-radius: 50% 50% 0% 50%;
  background-color: #cfd9e5;
  display: inline-block;
  z-index: 5;
}

.right-back {
  width: 70px;
  height: 50px;
  border-radius: 50% 50% 50% 0%;
  background-color: #cfd9e5;
  display: inline-block;
  margin-left: -20px;
  z-index: 5;
}

.left-back {
  width: 70px;
  height: 50px;
  border-radius: 50% 50% 0% 50%;
  background-color: #cfd9e5;
  display: inline-block;
  z-index: 5;
}

.sun {
  width: 150px;
  height: 150px;
  background: -webkit-linear-gradient(to right, #fcbb04, #fffc00);
  background: linear-gradient(to right, #fcbb04, #ffcc00);
  border-radius: 80px;
  display: inline;
  position: absolute;
}

.sunshine {
  animation: sunshines 2s infinite;
}

@keyframes sunshines {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }

  100% {
    transform: scale(6);
    opacity: 0;
  }
}

@keyframes clouds {
  0% {
    transform: translateX(35px);
  }

  50% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-50px);
  }
}


.lets-go-button {
  width: 8em;
  height: 2em;
  color: white;
  background-color: #cfd9e5;
  padding: 10px 20px;
  border-radius: 80px;
  box-shadow: 0px 0px 15px #FEED01;

}

.lets-go-button:hover {
  background-color: #2ecc71;
  box-shadow: 0px 0px 20px #2ecc71;
}

.lets-go-button:active {
  background-color: #27ae60;
  box-shadow: 0px 0px 10px #27ae60;
}


.timer-container {
  width: 25em;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  background-color: black;
  padding: 5px;
  border: 1px solid red;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.timer-label {
  font-size: 10px;
  color: red;

}

.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: red;
}


.quit-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none;
}


.moon-container {
  width: 170px;
  height: 50px;
  background: linear-gradient(0, #120c56, #000000);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px;
  border-radius: 20px;
  border-color: transparent;
}

.moon-container:hover {
  transform: scale(1.2);
  border-radius: 62px;
  box-shadow: 1px 0px 100px #ece1bd60;
  transition: all 1.4s;
}

.moon-text {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.moon {
  position: relative;
  border-color: transparent;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #F0EAD6;
  box-shadow: 0px 0px 10px #F0EAD6;
}

.moon::before {
  content: "";
  display: block;
  position: absolute;
  top: 22%;
  left: 45%;
  transform: translateX(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.4;
}

.moon::after {
  content: "";
  position: absolute;
  top: 67%;
  left: 30%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.3;
}

.mancha2 {
  content: "";
  width: 10px;
  height: 10px;
  background-color: rgba(51, 51, 51, 0.583);
  border-radius: 50%;
  position: absolute;
  top: 47%;
  left: 75%;
  transform: translate(-50%, -50%);
}

.moon:hover {
  box-shadow: 0px 0px 100px #F0EAD6;
  transition: all 1.4s;
}

.moon:active {
  transform: scale(1.5);
  transition: all 1.4s;
}